
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































.title {
  text-align: center;
}

.wysiwyg-intro {
  font-size: 1.8rem;
}

.alert {
  padding: 3.2rem 4rem;
  font-size: 1.8rem;
  border: 1px #636362 solid;
}

.alert__content {
  opacity: 0.6;

  ::v-deep {
    p {
      margin-bottom: 1rem;
    }

    ul {
      margin: 2rem 0;
    }
  }
}

.alert__flex {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.alert__button {
  padding-bottom: 1rem;
  line-height: 1;
  white-space: nowrap;
  border-bottom: 1px currentColor solid;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

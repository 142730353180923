
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































.toolbox-hero {
  ::v-deep {
    .hero {
      margin-bottom: 6.4rem;
    }
  }
}

.toolbox-intro {
  text-align: center;
}

.wysiwyg-intro {
  font-size: 1.8rem;
}

.bullet-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.bullet-list__item {
  --padding-inline-start: 2.4rem;

  // prettier-ignore
  @include fluid(--bullet-size, (xs: 15px, l: 24px));

  padding-top: 1.3rem;
  padding-inline-start: calc(var(--bullet-size) + var(--padding-inline-start));
  padding-block-end: 0;
  margin-block-end: 4rem;
  border-top: 1px currentColor solid;

  &::before {
    content: '';
    position: absolute;
    top: 1.3rem;
    left: 0;
    width: var(--bullet-size);
    height: var(--bullet-size);
    margin-right: var(--padding-inline-start);
    background-color: $c-fluo;
    border-radius: 50%;
  }
}

.alert {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem 2rem;
  padding: 3.2rem 4rem;
  border: 1px currentColor solid;
}

.alert__content {
  font-size: 1.8rem;
  font-style: italic;
  opacity: 0.6;
}

.alert__link {
  font-size: 1.8rem;
}

.intro__title,
.intro__wysiwyg,
.intro__lead {
  text-align: center;
}

.tabs {
  // prettier-ignore
  @include fluid(margin-bottom, (xs: 50px, l: 130px));

  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding-bottom: 1.6rem;
  border-bottom: 1px currentColor solid;

  @include mq($from: s) {
    flex-direction: row;
  }
}

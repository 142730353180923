
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































$breakpoint: l;

.timeline-container {
  @include liquid(margin-bottom, 100px, 241px);

  position: relative;
}

.timeline__list-item {
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
  padding-bottom: 2rem;
  border-left: 1px currentColor solid;

  @include mq($from: xs) {
    flex-basis: 80%;
    max-width: 80%;
  }

  @include mq($from: 600px) {
    flex-basis: 50%;
    max-width: 50%;
  }

  @include mq($from: m) {
    flex-basis: 37.5%;
    max-width: 37.5%;
  }

  @include mq($until: m) {
    &.show-details {
      .timeline__item {
        .timeline__item__content {
          transform: translateY(0%);
        }
      }
    }
  }
}

.timeline {
  display: flex;
  flex-wrap: nowrap;
  min-height: 570px;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
}

.timeline__item {
  --header-height: 84px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  height: 100%;
  padding: 0 2.4rem;
  background-color: $c-surface-light; // Needed in order for the mix blend mode of the buttons to work

  @include mq($from: m) {
    --header-height: 70px;

    &:hover {
      .timeline__item__content {
        transform: translateY(0%);
      }
    }
  }
}

.timeline__item__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  @include mq($from: m) {
    margin-bottom: 3rem;
  }
}

.timeline__item__title {
  display: flex;
  align-items: flex-start;
  font-style: normal;

  &::before {
    content: '';
    margin-top: 2px;
    display: inline-block;
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    margin-right: 1rem;
    border: 1px currentColor solid;
    border-radius: 50%;
  }
}

.timeline__item__date {
  margin-left: 2rem;
}

.timeline__item__toggle-details {
  width: 100%;
  margin-bottom: 1rem;
  font-size: 15px;
  text-align: center;

  @include mq($from: m) {
    display: none;
  }
}

::v-deep {
  .timeline__item__visual-container {
    position: absolute;
    top: 0;
    right: 2.4rem;
    left: 2.4rem;
    height: 100%;
  }

  .timeline__item__visual {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .timeline__item__content {
    position: relative;
    padding: 2rem 0;
    white-space: normal;
    background-color: $c-surface-light;
    transition: transform 700ms $easing;
    transform: translateY(calc(100% - var(--header-height)));
    // Fix the weird little line where we can see the visual underneath on the sides
    width: 102%;
    margin-left: -1%;

    .wysiwyg {
      font-size: 1.6rem;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}

.timeline-nav-button {
  @include liquid(padding, 10px, 25px);

  position: absolute;
  z-index: 1;
  top: 50%;
  border: 1px white solid;
  border-radius: 50%;
  transform: translateY(-50%);
  mix-blend-mode: difference;

  svg {
    @include liquid(width, 20px, 30px);
    @include liquid(height, 20px, 30px);

    display: block;
    width: 3rem;
    height: 3rem;
    stroke: white;
  }

  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }

  &:disabled {
    // TODO: Fancy animation
    display: none;
  }
}

.content {
  @include liquid(padding-top, 100px, 160px);

  border-top: 1px currentColor solid;
}

/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: #2d2d2d;
$c-gray-darker: #5e5e5e;
$c-gray-dark: #858585;
$c-gray-medium: rgb(111, 113, 117);
$c-gray: #b2b2b2;
$c-gray-light: #d8d8d8;
$c-gray-lighter: #e6e6e6;
$c-gray-lightest: #f5f5f5;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
$c-red: #f00;
$c-green: #17b603;
$c-lemon: #f8e71c;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-fluo: #d7fb53;
$c-surface-light: #f7f7f5;
$c-surface-dark: #252422;
$c-text-light: #f7f7f5;
$c-text-dark: #252422;
$c-text-grey: #c1c1c1;

/*
* Dimensions
*/
$wrapper-padding: 68px;
$content-width: 1440px;
$inner-content-width: $content-width - $wrapper-padding * 2;
$header-height: 79px;
$header-height-large: 114px;
$secondary-header-height: $header-height;
$secondary-header-height-large: $header-height-large;
$header-breakpoint: m;

/*
* Typography
*/
$base-font-size: 10px !default;

$ff-default: 'Suisse Intl', sans-serif;
$ff-alt: 'Mazius', serif;
$body-text: 1.8rem;
$body-text-m: 2.4rem;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem,
);

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';

/**
* MISC
*/
$transition-duration: 500ms;
$z-index-panel: 11;
$z-index-blur-overlay: 10;
$z-index-header: 9;
$secondary-card-visual-transition-delay: 70ms;

$scrollbar-width: 15px;
$hero-subtitle-margin-top: 3rem;

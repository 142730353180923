
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































.section {
  border-top: 1px currentColor solid;
}

.member {
  @include liquid(margin-bottom, 50px, 100px);
}

.member__button {
  text-align: initial;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.member__picture {
  @include liquid(margin-bottom, 20px, 36px);
}

.member__role {
  @include liquid(margin-bottom, 10px, 18px);
}

.member__name {
  display: inline;
}

.member__details {
  text-align: initial;
}

.member__details__address {
  font-style: normal;
}

.member__details__title {
  @include liquid(font-size, 20px, 36px);

  margin-bottom: 0.5em;
  line-height: 1.2;

  &:last-of-type {
    @include liquid(margin-bottom, 20px, 24px);
  }
}

.member__details__email {
  @include liquid(font-size, 18px, 20px);

  display: block;
}

.member__details__wysiwyg::v-deep {
  @include liquid(margin-top, 20px, 36px);

  .wysiwyg {
    @include liquid(font-size, 18px, 28px);
  }

  @include mq($from: s) {
    margin-top: 0;
  }
}


          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























.bullet-list__item__icon {
  display: flex;
  margin-left: auto;

  svg {
    width: 1em;
    height: 1em;
    stroke: currentColor;
  }
}

details {
  &[open] {
    .bullet-list__item__icon {
      transform: rotate(45deg);
    }
  }
}

details,
summary {
  &:focus {
    outline: none;
  }
}

::v-deep {
  .wysiwyg.details__content {
    > *:last-child {
      margin-bottom: 5.4rem;
    }
  }
}

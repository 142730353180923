
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































.our-services {
  @include liquid(padding-top, 80px, 160px);
  @include liquid(margin-bottom, 80px, 216px);

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: currentColor;
  }
}

.our-services__title {
  @include liquid(margin-bottom, 30px, 50px);

  text-align: center;
}

.services-nav {
  @include liquid(margin-bottom, 40px, 80px);
  @include liquid(font-size, 30px, 140px);

  display: flex;
  justify-content: center;
  align-items: baseline;

  li:not(:last-of-type) {
    display: flex;

    &::after {
      content: '/';
      margin: 0 0.25em;
      font-family: $ff-alt;
    }
  }
}

.services-nav__button {
  display: flex;
  align-items: center;
  font-size: inherit;
  text-decoration: none;
}

.services-nav__title {
  font-size: inherit;
  opacity: 0.25;

  &.active {
    opacity: 1;
  }
}

.services-nav__button__svg {
  width: 0.5em;
  height: 0.5em;
  margin: 0 0.25em;
  stroke: currentColor;
}

.video-player {
  @include liquid(margin-bottom, 40px, 80px);
}

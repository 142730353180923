
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















::v-deep {
  .title {
    @include liquid(margin-top, 10px, 160px);
    @include liquid(margin-bottom, 50px, 160px);
    @include liquid(font-size, 38px, 120px);

    text-align: center;
  }
}


          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































$bp-height: 860px;

.search-form {
  margin-top: var(--header-height);

  ::v-deep {
    .search-form__inner {
      padding-block: 3.5rem;
      border-bottom: 1px $c-text-dark solid;
    }
  }
}

.hero {
  ::v-deep {
    margin-top: 0;
    margin-bottom: 0;

    .hero {
      margin-top: 0;
      margin-bottom: 0;
    }

    .page__title--subpage {
      line-height: 1;
    }
  }

  &.search {
    ::v-deep {
      margin-top: var(--header-height);
    }
  }
}

.search-intro {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  line-height: 1;
  text-align: center;

  .for {
    // prettier-ignore
    @include fluid(font-size, (xs: 25px, l: 36px));
  }

  .keyword {
    // prettier-ignore
    @include fluid(font-size, (xs: 36px, l: 50px));

    font-style: italic;
    opacity: 0.6;
  }
}

.search-results {
  // prettier-ignore
  @include fluid(margin-top, (xs: 50px, l: 100px));

  @media (min-height: $bp-height) {
    // prettier-ignore
    @include fluid(margin-top, (xs: 50px, l: 170px));
  }
}

.search-form__search {
  // prettier-ignore
  @include fluid(margin-top, (xs: 25px, l: 75px));

  // prettier-ignore
  @include fluid(margin-bottom, (xs: 100px, l: 300px));

  max-width: 75rem;
  margin-inline: auto;
}

.no-results {
  // prettier-ignore
  @include fluid(margin-block, (xs: 50px, l: 200px));

  text-align: center;
}

.no-results__title {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-bottom: 7.2rem;
  line-height: 1;
}

.no-results__title__query {
  opacity: 0.6;
}
